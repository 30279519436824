<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('my_invoices')" :is-filter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('my_invoices')" :is-filter="false"/>
            </template>

            <div v-if="alertShow===true">
                <b-alert
                    :show="alertShow"
                    variant="danger"
                >
                    <span v-html="message"></span>
                </b-alert>
            </div>

            <div class="row" v-if="alertShow===false">
                <div class="col-12">
                    <table class="table table-bordered table-striped" v-if="data && data.length">
                        <tr>
                            <th>{{$t('date')}}</th>
                            <th>{{$t('amount')}}</th>
                            <th>{{$t('currency')}}</th>
                            <th class="w-40"></th>
                        </tr>
                        <tr v-for="invoice in data">
                            <td>{{invoice.date}}</td>
                            <td class="text-right">{{invoice.amount | formatNumber}}</td>
                            <td>{{invoice.currency}}</td>
                            <td><a :href="invoice.url" target="_blank" class="btn btn-outline-info">{{$t('view')}}</a></td>
                        </tr>
                    </table>
                    <div v-else-if="loaded===false">
                        <div class="text-center py-5 my-5 w-100" v-if="data && !data.length">
                            <div class="mb-5">
                                <i class="ri-file-2-line ri-5x text-muted"></i>
                            </div>
                            <h4 class="mb-4 text-black-50">{{ $t('you_dont_have_any_invoice') }}</h4>
                        </div>
                        <div class="spinner over text-center mt-5" v-else>
                            <b-spinner variant="primary"></b-spinner>
                        </div>
                    </div>
                </div>
            </div>

        </app-layout>
    </div>
</template>
<script>
import InvoiceService from "@/services/InvoiceService";
import AppLayout from "@/layouts/AppLayout";

import Header from "@/layouts/AppLayout/Header.vue";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue";
import qs from "qs";
import ConstantService from "@/services/ConstantService";

export default {
    components: {
        HeaderMobile,
        Header,
        AppLayout
    },
    metaInfo() {
        return {
            title: this.$t("my_invoices"),
        };
    },
    data() {
        return {
            national_id: this.$route.params.national_id,
            data:null,
            alertShow: null,
            message: null,
            loaded: false
        };
    },
    created() {
        this.checkMessage();
    },
    methods: {
        getInvoices(){
            if(this.national_id){
                const config = {
                    params: {
                        national_id: this.national_id
                    }
                };
                InvoiceService.customerInvoices(config)
                    .then(response=>{
                        this.data=response.data.data;
                        this.loaded=true;
                    })
                    .catch(e=>{
                        this.showErrors(e);
                    });
            }
            else {
                InvoiceService.myInvoices()
                    .then(response=>{
                        this.data=response.data.data;
                        this.loaded=true;
                    })
                    .catch(e=>{
                        this.showErrors(e);
                    });
            }
        },
        checkMessage() {
            const lang = this.$i18n.locale;
            const config = {
                params: {
                    key: lang == 'tr' ? "finance.student_invoices_message" : "finance.student_invoices_message_en"
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            ConstantService.getValue(config)
                .then(response => {
                    if (response.data.data && response.data.data.length > 10) {
                        this.message = response.data.data
                        this.alertShow=true;
                    }
                    else {
                        this.alertShow=false;
                        this.getInvoices();
                    }
                }).catch(err => {
                this.showErrors(err)
            })
        }
    },
};
</script>
